<template>
  <div>
    <header id="header" class="fixed-top border-bottom bg-main shadow-sm">
      <div class="container-fluid shadow-sm">
        <nav class="navbar navbar-expand-md navbar-dark px-0 pr-sm-3">
          <a
            @click="back"
            class="btn p-0 mr-md-4 text-light h5 mb-0"
          >
            <i class="fas fa-arrow-left"></i>
          </a>
          <span
            class="
              navbar-brand
              bg-transparent
              rounded
              p-0
              text-center
              mr-0 mr-md-5
            "
          >
            <span class="h6 font-weight-bold text-truncate"
              >Pesanan - Edit</span
            >
          </span>
          <a
            href="javascript:void(0);"
            class="btn px-0"
            @click="deleteData"
            title="Batalkan pesanan..."
          >
            <i class="fa fa-trash text-light"></i>
          </a>
        </nav>
      </div>
      <!-- .container -->
    </header>

    <div class="container-fluid mt-header">
      <div class="row" id="body-sidemenu">
        <!-- MAIN -->
        <div id="main-content" class="col with-fixed-sidebar">
          <div class="row mt-4 mb-5">
            <div class="col-12">
                <Notif />
              <Form
                @submit="update"
                :validation-schema="schema"
                v-slot="{ errors }"
                method="post"
                class="mb-3 needs-validation"
              >
                <div class="row">
                  <div class="col-12 col-md-6">
                    <div class="form-group">
                      <label for="nama"
                        >Pembeli:<span class="text-danger ml-1">*</span></label
                      >
                      <Field
                        type="hidden"
                        name="pelanggan_id"
                        v-model="state.form.pelanggan_id"
                      />
                      <Field
                        type="text"
                        class="form-control"
                        :class="{ 'is-invalid': errors.pelanggan_id }"
                        id="pelanggan_id"
                        name="pelanggan_id"
                        v-model="state.form.pelanggan_name"
                        disabled
                      />
                      <div class="valid-feedback">Valid.</div>
                      <div class="invalid-feedback">
                        {{ errors.pelanggan_id }}
                      </div>
                    </div>
                  </div>
                  <!-- .col-* -->
                  <div class="col-12 col-md-6">
                    <div class="form-group">
                      <label for="sel2"
                        >PO Grup:<span class="text-danger ml-1">*</span></label
                      >
                      <Field
                        name="po_id"
                        as="select"
                        class="form-control"
                        :class="{ 'is-invalid': errors.po_id }"
                        v-model="state.form.po_id"
                        disabled
                      >
                        <option value="">Pilih PO Grup Aktif...</option>
                        <option
                          v-for="po in state.poGroups"
                          :key="po.id"
                          :value="po.id"
                          @click="selectPO(po)"
                        >
                          {{ po.name }}
                        </option>
                      </Field>
                      <div class="valid-feedback">Valid.</div>
                      <div class="invalid-feedback">{{ errors.po_id }}</div>
                    </div>
                  </div>
                  <!-- .col-* -->
                  <div class="col-12 col-md-6">
                    <div class="form-group">
                      <label for="status"
                        >Status:<span class="text-danger ml-1">*</span></label
                      >
                      <Field
                        name="status"
                        as="select"
                        class="form-control"
                        :class="{ 'is-invalid': errors.status }"
                        v-model="state.form.status"
                      >
                        <option :value="1">Aktif</option>
                        <option :value="0">Inaktif</option>
                      </Field>
                      <div class="valid-feedback">Valid.</div>
                      <div class="invalid-feedback">{{ errors.status }}</div>
                    </div>
                  </div>
                  <!-- .col-* -->
                  <div class="col-12 col-md-6">
                    <div class="form-group mb-0">
                      <label for="sel3"
                        >Pengiriman:<span class="text-danger ml-1"
                          >*</span
                        ></label
                      >
                      <Field
                        name="pengiriman"
                        as="select"
                        class="form-control"
                        :class="{ 'is-invalid': errors.pengiriman }"
                        v-model="state.form.pengiriman"
                      >
                        <option value="">Pilih pengiriman...</option>
                        <option value="Antar Langsung">Antar Langsung</option>
                        <option value="Diambil">Diambil</option>
                        <option value="Reseller">Reseller</option>
                        <option value="Ojek Instan">Ojek Instan</option>
                        <option value="Hari Yang Sama">Hari Yang Sama</option>
                        <option value="Paket">Paket</option>
                      </Field>
                      <div class="valid-feedback">Valid.</div>
                      <div class="invalid-feedback">
                        {{ errors.pengiriman }}
                      </div>
                    </div>
                    <div id="divAlamat" class="form-group mb-0 d-none">
                      <!-- dimunculkan bila pengiriman val=1/3/4/5/6 -->
                      <!-- masukkan alamat unt reseller disabled -->
                      <textarea
                        class="form-control form-control-sm"
                        rows="2"
                        id="alamat"
                        name="alamat"
                        placeholder="Masukkan alamat pengiriman..."
                        required
                      ></textarea>
                      <div class="valid-feedback">Valid.</div>
                      <div class="invalid-feedback">Isian ini wajib diisi.</div>
                    </div>
                    <div id="divEstimasi" class="form-group mb-0 d-none">
                      <!-- dimunculkan bila pengiriman val=4/5/6 >>> masuk ke invoice -->
                      <input
                        type="text"
                        class="form-control form-control-sm currency"
                        id="estimasi"
                        placeholder="Masukkan perkiraan biaya kirim..."
                        name="estimasi"
                        value=""
                      />
                    </div>
                  </div>
                  <!-- .col-* -->
                  <div class="col-12 col-md-6" v-if="!state.loading">
                    <div class="form-group">
                      <label for="modal"
                        >Ongkir: </label
                      >
                      <currency-input
                        class="form-control currency"
                        :class="{ 'is-invalid': errors.ongkir }"
                        v-model="state.form.ongkir"
                        :options="{ currency: 'IDR' }"
                        name="ongkir"
                      />
                      <Field
                        type="hidden"
                        v-model="state.form.ongkir"
                        name="ongkir"
                      />
                      <div class="valid-feedback">Valid.</div>
                      <div class="invalid-feedback">{{ errors.ongkir }}</div>
                    </div>
                  </div>
                  <!-- .col-* -->
                  <div class="col-12 col-md-6">
                    <div class="form-group mt-3">
                      <label for="catatan">Catatan:</label>
                      <Field
                        type="hidden"
                        name="catatan"
                        v-model="state.form.catatan"
                      />
                      <textarea
                        class="form-control"
                        :class="{ 'is-invalid': errors.catatan }"
                        rows="2"
                        id="catatan"
                        name="catatan"
                        v-model="state.form.catatan"
                      ></textarea>
                      <div class="valid-feedback">Valid.</div>
                      <div class="invalid-feedback">{{ errors.catatan }}</div>
                    </div>
                  </div>
                  <!-- .col-* -->
                </div>
                <!-- .row -->

                <hr class="no-side-margin" />

                <div class="row" v-if="state.isPo">
                  <div class="col-12">
                    <table class="table table-sm table-bordered mb-0" v-if="state.haveProduct">
                      <thead>
                        <tr>
                          <th>produk</th>
                          <th>Jumlah</th>
                          <th>Total Harga</th>
                        </tr>
                      </thead>
                      <tbody v-if="chart.length > 0">
                        <tr v-for="(item, index) in chart" :key="index">
                          <td class="namaProd text-truncate">{{ item.data.name }}</td>
                          <td class="jmlhProd">{{ item.jumlah }}</td>
                          <td class="hargaProd text-right currency">Rp. {{ hitungJumlah((Number(item.data.price) + Number(item.data.margin)), item.jumlah) }}</td>
                          <td class="text-center">
                            <a class="delBtn" @click="deletePesanan(index)"><i class="fa fa-times"></i></a>
                          </td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <td colspan="2">&nbsp;</td>
                          <td class="subTotal text-right currency">Rp. {{ changeMoney(total) }}</td>
                          <td>&nbsp;</td>
                        </tr>
                      </tfoot>
                    </table>
                    <a
                      id="productBtn"
                      class="btn btn-sm btn-outline-main py-0"
                      title="Tambah/edit produk"
                      data-toggle="modal" data-target="#produkModal"
                      ><i class="fa fa-plus mr-1"></i>Tambah/Edit Produk</a
                    >
                  </div>
                  <!-- .col -->
                </div>
                <!-- .row -->

                <hr class="no-side-margin" />

                <div class="row mt-3">
                  <div class="col text-center">
                    <button
                      type="submit"
                      class="btn btn-main"
                      title="Simpan"
                    >
                      <span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true" v-if="state.loading"></span>
                      <i class="fa fa-floppy-o mr-1" v-else></i>Simpan
                    </button>
                    <button
                      type="reset"
                      class="btn btn-light ml-1"
                      title="Reset isian"
                    >
                      <i class="fas fa-sync-alt mr-1"></i>Reset
                    </button>
                    <a
                      href="#"
                      class="btn btn-light ml-1"
                      title="Batal"
                      @click="batal"
                      ><i class="fas fa-times mr-1"></i>Batal</a
                    >
                  </div>
                  <!-- .col-* -->
                </div>
                <!-- .row -->
              </Form>
            </div>
            <!-- .col -->
          </div>
          <!-- .row -->
        </div>
        <!-- main-content -->
      </div>
      <!-- .row -->
    </div>
    <!-- .container -->

    <div class="modal fade" id="produkModal">
      <div class="modal-dialog modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title">Pilih Produk</h6>
            <button type="button" class="close" data-dismiss="modal">
              &times;
            </button>
          </div>
          <div class="spinner-grow text-primary m-2" role="status" v-if="state.loadProduk">
            <span class="sr-only">Loading...</span>
          </div>
          <div class="modal-body" v-else>
            <form @submit.prevent="searchData">
              <div class="input-group input-group-sm">
                  <div class="input-group">
                  <input type="text" class="form-control" placeholder="Cari..."
                  v-model="state.search" />
                  <span class="input-group-append">
                      <button type="submit" class="btn btn-outline-main"><i class="fa fa-search"></i
                  ></button>
                  </span>
                  </div>
              </div>
            </form>
            <hr class="no-side-margin" />
            <ul class="list-group" id="productList">
              <li
                class="list-group-item text-truncate"
                v-for="pro in state.produk"
                :key="pro.id"
              >
                <span class="nama-produk text-main mb-0" @click="selectProduk(pro.id)">{{ pro.name }}</span>
                <small class="text-muted mb-0">
                  <span class="currency">Rp. {{ changeMoney(Number(pro.price) + Number(pro.margin)) }}</span></small
                >
                <div class="input-group input-group-sm my-0" v-show="pro.id === state.isProduk">
                  <input
                    type="number"
                    class="form-control form-control-sm w-25"
                    placeholder="Jumlah..."
                    min="0"
                    max="999"
                    v-model="state.jumlah"
                  />
                  <div class="input-group-append">
                    <a
                      class="addProduct input-group-text bg-transparent"
                      @click="addProduct(pro)"
                      ><i class="fa fa-plus mr-2"></i>Tambah</a
                    >
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="modal-footer">
            <a href="" class="btn btn-sm btn-outline-secondary"
              ><i class="fa fa-plus mr-1"></i>Tambah Produk</a
            >
            <button
              type="button"
              class="btn btn-sm btn-outline-secondary"
              data-dismiss="modal"
            >
              <i class="fa fa-times mr-1"></i>Tutup
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- #produkModal -->
  </div>
</template>
<script>
import { reactive, onMounted, ref, watchEffect } from "vue";
import { useRoute, useRouter } from "vue-router";
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import axios from "axios";
import Notif from "../../../helpers/Notif.vue";
import { useVueSweetAlert2 } from "../../../useVueSweetAlert2.js";
import $ from "jquery";
import CurrencyInput from "../../../helpers/CurrencyInput.vue";

export default {
  setup() {
    //ref
    const chart = ref([]);
    const jumlah = ref(0);
    const total = ref(0);
    //
    const route = useRoute();
    const router = useRouter();
    const $swal = useVueSweetAlert2();
    const state = reactive({
      id: "",
      loadProduk: false,
      form: {
        pelanggan_id: "",
        pelanggan_name: "",
        status: "",
        po_id: "",
        pengiriman: "",
        catatan: "",
        produk: [],
        ongkir: 0,
      },
      loading: false,
      poGroups: {},
      produk: {},
      originalProduk: {},
      isPo: true,
      haveProduct: false,
      search: '',
    });
    const batal = () => {
      $swal
        .fire({
          text: "Anda yakin meninggalkan form isian ini?  Perubahan tidak akan disimpan.",
          showCancelButton: true,
          confirmButtonText: "Ya",
          cancelButtonText: "Tidak",
        })
        .then((result) => {
          if (result.isConfirmed) {
            router.push({ path: "/jual/pesanan/customer/"+state.id });
          }
        });
    };
    const loadPo = async () => {
      await axios.get("/api/seller/getPoGroup").then((res) => {
        if (res.data.success) {
          state.poGroups = res.data.data;
        }
      });
    };
    const loadProduk = async (po_id) => {
      state.loadProduk = true;
      await axios.get("/api/seller/getProduk/" + po_id).then((res) => {
        if (res.data.success) {
          state.produk = res.data.data;
          state.originalProduk = res.data.data;
        }
        state.loadProduk = false;
      });
    };
    //load data
    const loadPesananData = async () => {
      state.loading = true;
      const id = route.params.id;
      await axios.get("/api/seller/pesanan/" + id).then((res) => {
        if (res.data.success) {
          state.id = res.data.data.pelanggan_id;
          loadProduk(res.data.data.po.group_id);
          //process data
          chart.value = [];
          res.data.data.detail.map((dt) => {
            dt.produk.margin = dt.margin;
            let produk = {
              data: dt.produk,
              jumlah: dt.jumlah,
            };
            chart.value.push(produk);
          });
          state.form.pelanggan_id   = res.data.data.pelanggan_id;
          state.form.pelanggan_name = res.data.data.pelanggan.name;
          state.form.status         = res.data.data.status;
          state.form.catatan        = res.data.data.catatan;
          state.form.pengiriman     = res.data.data.pengiriman;
          state.form.po_id          = res.data.data.po_id;
          state.form.ongkir         = Number(res.data.data.ongkir);
        }
        state.loading = false;
      }, () => {
        state.loading = false;
      });
    };
    onMounted(() => {
      loadPo();
      loadPesananData();
    });
    //start validate
    const schema = Yup.object().shape({
      status: Yup.string().required("Pilih Salah satu"),
      pengiriman: Yup.string().required("Pilih Salah satu"),
      po_id: Yup.string().required("Pilih Salah satu"),
      pelanggan_id: Yup.string().required("Pilih Salah satu"),
    });
    const selectProduk = (id) => {
        state.isProduk = id;
        state.jumlah = 0;
    }
    const addProduct = (produk) => {
      if(state.jumlah > 0 && state.jumlah <= 100){
        const check = (obj) => {
          return obj.data.id === produk.id;
        }
        const isUpdate = chart.value.some(check);
        if(isUpdate){
          chart.value.map((item) => {
            if(item.data.id === produk.id){
              item.jumlah = Number(state.jumlah);
            }
          })
        }else{
          const pro = {
              data: produk,
              jumlah: state.jumlah
          }
          chart.value.push(pro);
        }      
        $('#produkModal').modal('toggle');
      }else{
        $swal.fire(
          '',
          'Pesanan harus lebih dari 0 dan max 100'
        )
      }
    }
    const hitungJumlah = (price, jml) => {
      const harga   = Number(price)
      const jumlah  = Number(jml)
      let cur = harga * jumlah;
      //format
      let mo = (cur).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
      let ret = mo.split(".")[0]; 
      return ret;
    }
    const changeMoney = (dt) => {
        let cur = Math.ceil(Number(dt));
        let mo = (cur).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
        let ret = mo.split(".")[0]; 
        return ret; 
    }
    const deletePesanan = (index) => {
      $swal.fire({
        title: 'Hapus Pesanan?',
        text: "",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Hapus!',
        cancelButtonText: 'Tidak!'
      }).then((result) => {
        if (result.isConfirmed) {
          chart.value.splice(index,1);
        }
      })
    }
    watchEffect(() => {
      if(chart.value.length > 0){
        state.haveProduct = true;
        //count total
        total.value = 0;
        chart.value.map((item) => {
          total.value += Number(item.jumlah) * (Number(item.data.price) + Number(item.data.margin));
        })
      }else{
        state.haveProduct = false;
        total.value = 0;
      }
    })
    const deleteData = () => {
        $swal.fire({
        title: 'Hapus Pesanan?',
        text: "Apakah Anda Yakin",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Hapus!',
        cancelButtonText: 'Tidak!'
      }).then((result) => {
        if (result.isConfirmed) {
            const id = route.params.id;
            axios.delete('/api/seller/pesanan/'+id).then((res) => {
                if(res.data.success){
                    $swal.fire({
                        icon: "success",
                        title: "Selamat",
                        text: "Pesanan Berhasil Dihapus!",
                    });
                    router.push({ path: "/jual/pesanan-group" });
                }
            })
        }
      })
    }
    const update = () => {
        //check have product
      if(chart.value.length > 0){
        const id = route.params.id;
        state.form.produk = chart.value;
        axios.put("/api/seller/pesanan/"+id, state.form).then((res) => {
          if (res.data.success) {
            $swal.fire({
              icon: "success",
              title: "Selamat",
              text: "Pesanan Berhasil DiRubah!",
            });
            router.push({ path: "/jual/pesanan-group" });
          }
        });
      }else{
        $swal.fire({
          icon: "info",
          title: "Warning!",
          text: "Minimal Anda Harus Memilih 1 Produk!",
        });
      }
    }
    //search on object produk
    const searchData = () => {
        let result = [];
        const data = state.originalProduk;
        Object.values(data).find((dt, index) => {
          if(dt.name.match(state.search)){
            result.push(data[index]);
          }
        })
        state.produk = result;
    }
    const back = () => {
      $swal.fire({
          text: "Anda yakin meninggalkan halaman ini saat proses belum selesai & data belum tersimpan?",
          showCancelButton: true,
          confirmButtonText: 'Ya',
          cancelButtonText: 'Tidak'
      }).then((result) => {
          if (result.isConfirmed) {
            window.history.back();
          }
      })
    }
    return {
      state,
      batal,
      schema,
      update,
      jumlah,
      total,
      chart,
      hitungJumlah,
      changeMoney,
      deletePesanan,
      selectProduk,
      addProduct,
      deleteData,
      searchData,
      back
    };
  },
  components: {
    Field,
    Form,
    Notif,
    CurrencyInput,
  },
};
</script>